<template>
  <div>

    <div class="inside-os">
      <div class="water-os">
        <!-- <el-form :model="form" :rules="rules"> -->
        <table class="com-table">
          <tr>
            <th colspan="2">系统讯息</th>
          </tr>
          <tr v-for="item in list" :key="item.id">
            <td style="width:150px">{{item.begintime|substrSome}}</td>
            <td>{{item.info}}</td>
          </tr>

        </table>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "",
  props: [""],
  data() {
    return {
      list: [],
    };
  },

  components: {},
  created() {
    // 查询公告信息  7701 (*系统讯息   7700,弹窗   7701,通告   7702)
    this.getSysInfo("7701");
  },
  mounted() {},

  computed: {
    ...mapState(["userInfo", "accInfo", "currentGame"]),
  },

  methods: {
    // 查询系统公告
    getSysInfo(showtype) {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        showtype,
        gametype: "lottery",
      };
      this.$http.post("getnoticeinfo", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          this.list = resObj;
          this.list.sort((a,b)=>b.begintime-a.begintime)
          // console.log(resObj, "2222");
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
  },
};
</script>
<style  scoped>
.flextd {
  width: 100%;
  height: 100%;
  /* line-height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.red {
  color: red;
}
.zhankai {
  position: absolute;
  /* top: 3px; */
  right: 4px;
}
.com-table.w-t3 td,
.com-table.w-t3 th {
  width: auto !important;
  text-align: center !important;
  vertical-align: middle;
  padding-right: 0 !important;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
td {
  position: relative;
  vertical-align: middle;
}
.water-os .com-table.w-t2 .tab span.selected,
.water-os .com-table.w-t2 .tab span:hover {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #fff;
}
.skin-brown .inside-os .water-os .com-table.w-t2 .tab span.selected,
.skin-brown .inside-os .water-os .com-table.w-t2 .tab span:hover {
  background: #dfb55e;
  text-shadow: none;
}
.skin-red .inside-os .water-os .com-table.w-t2 .tab span.selected,
.skin-red .inside-os .water-os .com-table.w-t2 .tab span:hover {
  background: #c2455c;
  text-shadow: none;
}
.skin-blue .inside-os .water-os .com-table.w-t2 .tab span.selected,
.skin-blue .inside-os .water-os .com-table.w-t2 .tab span:hover {
  background: #2161b3;
  text-shadow: none;
}
.water-os .com-table.w-t2 .tab span {
  margin: 5px 0 0 5px;
  width: calc((100% - 50px) / 9);
  height: 30px;
  line-height: 30px;
  background: #ddd;
  text-align: center;
  text-shadow: 1px 1px 0 #eee;
  cursor: pointer;
}
.water-os .com-table.w-t2 .tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
th.kkc {
  text-align: center !important;
  vertical-align: middle !important;
}
.skin-brown .inside-os .com-table th.kkc {
  background: linear-gradient(180deg, #fff, #fff 15px, #fdeee1 51%, #fdeee1);
}
.skin-red .inside-os .com-table th.kkc {
  background: linear-gradient(180deg, #fff, #fff 15px, #ffefee 51%, #ffefee);
}
.skin-blue .inside-os .com-table th.kkc {
  background: linear-gradient(180deg, #fff, #fff 15px, #fef4ea 51%, #fef4ea);
}
.water-os .com-table.w-t2 th {
  width: 60px;
}
.water-os .com-table.w-t2 {
  margin-top: 5px;
}
.water-os .btn span {
  width: 80px;
}
.inside-os .button {
  padding: 0 8px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.water-os .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding: 15px 0 0 260px;
  width: 242px;
}
.inside-os .com-table td,
.inside-os .com-table th {
  padding: 4px 0;
  text-align: center;
  font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
}
.water-os .com-table th {
  text-align: left;
  padding-left: 10px;
}
.water-os .com-table input {
  width: 230px;
  padding: 0 5px;
}

.inside-os input[type="password"],
.inside-os input[type="text"] {
  height: 26px;
  line-height: 26px;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 2px 0 #e8e8e8;
  box-shadow: inset 0 1px 2px 0 #e8e8e8;
  -webkit-appearance: none;
}
.water-os .com-table tr td:last-child {
  text-align: left;
  padding-left: 15px;
}
.water-os .com-table tr td:first-child {
  width: 100px;
  text-align: right;
  padding-right: 15px;
}
.inside-os .com-table {
  width: 100%;
}
.water-os {
  padding-top: 10px;
}
/* .inside-os {
  width: 1018px;
  padding: 0 0 10px 10px;
  color: #4f4d4d;
} */
</style>